.button-demo .p-button {
    margin-right: .5rem;
}

.button-demo .p-buttonset .p-button {
    margin-right: 0;
}

.button-demo .sizes .button {
    margin-bottom: .5rem;
    display: block;
}

.button-demo .sizes .button:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 960px) {
    .button-demo .p-button {
        margin-bottom: .5rem;
    }
    .button-demo .p-button:not(.p-button-icon-only) {
        display: flex;
        width: 100%;
    }
    .button-demo .p-buttonset .p-button {
        margin-bottom: 0;
    }
}