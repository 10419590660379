body {
	margin: 0;
	height: 100%;
	font-family: "FujitsuSans";
	/* , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
	font-family: "FujitsuSans";
	src: local("FujitsuSans"), url(../assets/fonts/FujitsuSansRegular.ttf) format("truetype");
}

:root {
	--amplify-primary-color: #a30b1a;
	--amplify-primary-tint: red;
	--amplify-font-family: "FujitsuSans";
}

.startUpMargin input {
	width: 3vw !important;
}

.inputOnDataTable input {
	width: 5vw !important;
}

.inputOnMonthly input {
	width: 5vw !important;
}

select {
	text-align-last: center;
}

.formContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}

.formBody {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	width: 70vw;
	opacity: 1;
}

.formTable {
	width: 70vw;
	table-layout: fixed;
	height: 68vh;
}

.alignCostRight {
	text-align: right;
	padding-right: 4.2vw;
}

.pageContent {
	height: "calc(100vh - 137px)"
}

.removeTextDecoration {
	text-decoration: none;
}

.card {
	margin: 10;
	/* background-color: #a30b1a; */
	background-color: #962127;
}